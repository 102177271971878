import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import {
  LandingPage,
  LoginPage,
  SignUpPage,
  HomePage,
  GalleryPage,
  TermsAndConditionsPage,
  SpecificationPage,
  FundraisePage,
  CatalogPage,
  SpecialsPage,
  ClearancePage,
  ContactPage,
  CareerPage,
  FAQPage,
  AnnouncementPage,
  FontsPage,
  ColorsPage,
  SizeAndLocationPage,
  DesignPage,
  OrderPage,
  TermsPage,
  ProfilePage,
  PaymentPage,
} from "./pages";
import { Footer } from "./widgets/footer_v2";
import { Header } from "./widgets/header";
import { reduxStore } from "./reducer";
import styled from "styled-components";
import { defaultFontFamily } from "./constants";
import { LoginBoundary } from "./LoginBoundary";
import { ToastContainer } from "@happeouikit/toast";
import { ShirtDesigner } from "./widgets/shirtDesigner/ShirtDesigner";

export default class StitchZone extends React.Component {
  private nonLoggedInRoutes = (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/landing" element={<LandingPage />}></Route>
      <Route path="*" element={<LandingPage />}></Route>
    </Routes>
  );

  private loggedInRoutes = (
    <Routes>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/landing" element={<LandingPage />}></Route>
      <Route path="/careers" element={<CareerPage />} />
      <Route path="/faq" element={<FAQPage />}></Route>
      <Route
        path="/terms-and-conditions"
        element={<TermsAndConditionsPage />}
      ></Route>
      <Route path="terms" element={<TermsPage />}></Route>
      <Route path="/shipping-rates" element={<ColorsPage />}></Route>
      <Route path="/photo-gallery" element={<GalleryPage />}></Route>
      <Route path="/contact" element={<ContactPage />}></Route>
      <Route path="/specifications" element={<SpecificationPage />} />
      <Route path="/fundraise" element={<FundraisePage />} />
      <Route path="/my-profile" element={<ProfilePage />} />
      <Route path="/my-orders" element={<OrderPage />} />
      <Route path="/payment" element={<PaymentPage />}></Route>
      <Route
        path="/catalog/:categoryId/subcategories/:subCategoryId/products/:productId/design"
        element={<ShirtDesigner />}
      />
      <Route
        path="/catalog/:categoryId/subcategories/:subCategoryId/products"
        element={<CatalogPage />}
      />
      <Route
        path="/catalog/:categoryId/subcategories"
        element={<CatalogPage />}
      />
      <Route path="/catalog/:categoryId" element={<CatalogPage />} />
      <Route path="/catalog" element={<CatalogPage />} />
      <Route path="/specials" element={<SpecialsPage />} />
      <Route path="/clearance" element={<ClearancePage />} />
      <Route path="/designs" element={<DesignPage />} />
      <Route path="/fonts" element={<FontsPage />} />
      <Route path="/colors" element={<ColorsPage />} />
      <Route path="/size-and-location" element={<SizeAndLocationPage />} />
      <Route
        path="*"
        element={
          <h1> Content to be updated </h1>
        }
      />
    </Routes>
  );

  render(): React.ReactNode {
    return (
      <AppContainer>
        <ToastContainer position="right" />
        <Provider store={reduxStore}>
          <AnnouncementPage />
          <BrowserRouter>
            {!["/login", "/sign-up"].includes(window.location.pathname) && (
              <Header />
            )}
            <div style={{marginTop:`${(window.location.pathname.includes("catalog"))?"75px":"90px"}`, marginBottom:`${(window.location.pathname === '/' || window.location.pathname.includes("catalog"))?"":"20px"}`}} >
            <LoginBoundary
              routesAvailableForNonLoggedInUsers={this.nonLoggedInRoutes}
              routesAvailableForLoggedInUsers={this.loggedInRoutes}
            ></LoginBoundary>
            </div>
            {/* <div style={{marginBottom:"50px"}}></div> */}
            {true && <Footer isActive={true} />}
          </BrowserRouter>
        </Provider>
      </AppContainer>
    );
  }
}

const AppContainer = styled.div`
  font-family: ${defaultFontFamily};
  .bold {
    font-weight: bold;
  }
`;

/* * {
    font-family: ${defaultFontFamily};
  } */
